<template>
  <block title="举办城市">
    <v-chart class="chart" :option="option"/>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ZhanhuiCityStatBlock",
  props:{
    params:Object
  },
  components: {
    VChart,
    Block
  },
  data(){
    return {
      data:[],
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  computed:{
    option(){
      const topTen = this.data.slice(0,10).reverse()
      return {
        grid:{
          left:'60px',
          top:20,
          bottom:20
        },
        tooltip: {
          trigger: "item",
        },
        yAxis: {
          type: 'category',
          data: topTen.map(i=>i.name),
          offset: 0
        },
        xAxis: {
          type: 'value',
        },
        series: [
          {
            data: topTen.map(i=>i.value),
            type: 'bar',
            label:{
              show:true,
              position: 'right'
            }
          }
        ]
      }
    },
  },
  methods:{
    async init(){
      this.data=(await api.getZhanhuiCityStat(this.params)).data.filter(i=>i.name)
    },
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
