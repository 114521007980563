<template>
  <block title="行业探查">
    <h3 style="text-align: center;">{{zhanhui.sshy}}同期展会</h3>
    <el-timeline>
      <el-timeline-item
          v-for="(activity, index) in data"
          :key="index"
          :timestamp="activity.jbkssj" hide-timestamp>
        <div class="timeline-content">
          <span style="flex: none;margin-right: 10px;">{{activity.jbkssj}}</span>
          <span style="flex: none;margin-right: 10px;">{{ activity.province }}</span>
          <a class="link" :href="$router.resolve({path:'/zhanhui/details',query:{id:activity.id}}).href" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ activity.zhmc }}</a>
        </div>
      </el-timeline-item>
    </el-timeline>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ZhanhuiHytcBlock",
  components: {Block},
  props: {
    zhanhui: Object
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.data = (await api.listZhanhui({
        sshy: this.zhanhui.sshy,
        page: 0,
        size: 10,
        sort: 'jbkssj,DESC'
      })).data.content
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
