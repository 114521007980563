<template>
  <div class="page-content">
    <div class="page-tips">数据来源于E展网</div>
    <div class="filters" style="margin-top: 10px;" v-sticky>
      <div class="filters-left">{{filterParams.sshy}}行业展会专题分析</div>
      <div class="filters-right">
        <el-select v-model="filterForm.sshy" clearable placeholder="行业选择" style="width: 150px" size="small">
          <el-option v-for="item in industries" :key="item.id" :value="item.name" :label="item.name"></el-option>
        </el-select>
        <el-select v-model="filterForm.area" clearable placeholder="全部区域" style="margin-left: 10px;width: 120px;"
                   size="small">
          <el-option v-for="item in areas" :key="item" :value="item" :label="item"></el-option>
        </el-select>
        <el-date-picker style="width: 250px;margin-left: 10px;" :clearable="false"
                        v-model="filterForm.dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期" size="small" unlink-panels
                        end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
    </div>
    <el-row>
      <el-col :span="24">
        <div class="indicators">
          <div class="indicator" v-for="item in indicators" :key="item.title">
            <div class="indicator-top">
              <span v-if="item.onclick" class="indicator-value indicator-value-link" @click="item.onclick">{{item.value}}</span>
              <span v-else class="indicator-value">{{item.value}}</span>
              <span class="indicator-unit">{{ item.unit }}</span>
            </div>
            <div class="indicator-bottom">
              <img :src="item.icon" class="indicator-icon">
              <span class="indicator-title">{{ item.title }}</span>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-year-stat-block :params="filterParams"></zhanhui-year-stat-block>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <zhanhui-area-stat-block :params="filterParams"></zhanhui-area-stat-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="8">
        <zhanhui-mj-stat-block :params="filterParams"></zhanhui-mj-stat-block>
      </el-col>
      <el-col :span="8">
        <zhanhui-jbzq-stat-block :params="filterParams"></zhanhui-jbzq-stat-block>
      </el-col>
      <el-col :span="8">
        <zhanhui-city-stat-block :params="filterParams"></zhanhui-city-stat-block>
      </el-col>
    </el-row>
    <el-row :gutter="30">
      <el-col :span="12">
        <zhanhui-top-dw-block :params="filterParams" dw-type="主办"></zhanhui-top-dw-block>
      </el-col>
      <el-col :span="12">
        <zhanhui-top-dw-block :params="filterParams" dw-type="承办"></zhanhui-top-dw-block>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import ZhanhuiAreaStatBlock from "./ZhanhuiAreaStatBlock";
import ZhanhuiMjStatBlock from "./ZhanhuiMjStatBlock";
import ZhanhuiCityStatBlock from "./ZhanhuiCityStatBlock";
import ZhanhuiJbzqStatBlock from "./ZhanhuiJbzqStatBlock";
import hyhz from '../assets/images/hyhz.png'
import pqgl from '../assets/images/pqgl.png'
import ywc from '../assets/images/ywc.png'
import dkmj from '../assets/images/dkmj.png'
import fz from '../assets/images/fz.png'
import sjfw from '../assets/images/sjfw.png'
import jlr from '../assets/images/jlr.png'
import api from "../api"
import moment from "moment";
import ZhanhuiYearStatBlock from "./ZhanhuiYearStatBlock";
import ZhanhuiTopDwBlock from "./ZhanhuiTopDwBlock";

export default {
  name: "ZhanhuiIndustryHome",
  components: {
    ZhanhuiAreaStatBlock,
    ZhanhuiMjStatBlock,
    ZhanhuiCityStatBlock,
    ZhanhuiJbzqStatBlock,
    ZhanhuiYearStatBlock,
    ZhanhuiTopDwBlock
  },
  data() {
    const filterForm = {
      sshy: "食品饮料",
      area: null,
      dateRange: [moment().subtract(4, "year").startOf('year').toDate(),
        moment().endOf('year').toDate()],
    };
    const filterParams = {
      sshy: null,
      area: null,
      startDate: null,
      endDate: null,
      type:'展会',
    }
    this.copyFilterParams(filterForm, filterParams)
    return {
      filterForm,
      filterParams,
      areas: ['华东', '华南', '西南', '华北', '西北', '华中', '东北'],
      industries: [],
      indicators: [],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近3年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(2, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          },
          {
            text: '最近5年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(4, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          },
          {
            text: '最近10年',
            onClick(picker) {
              picker.$emit('pick', [moment().subtract(9, "years").startOf('year'),
                moment().endOf('year').toDate()]);
            }
          }]

      },
    }
  },
  watch: {
    filterForm: {
      deep: true,
      handler() {
        this.reloadData()
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.industries = (await api.listAllIndustries()).data
      await this.loadData()
    },
    copyFilterParams(form, params) {
      params.sshy = form.sshy
      params.area = form.area
      if (form.dateRange) {
        params.startDate = moment(form.dateRange[0]).format('YYYY-MM-DD')
        params.endDate = moment(form.dateRange[1]).format('YYYY-MM-DD')
      } else {
        params.startDate = null
        params.endDate = null
      }
    },
    async reloadData() {
      this.copyFilterParams(this.filterForm, this.filterParams)
      await this.loadData()
    },
    async loadData() {
      const data = (await api.getZhanhuiTotalStat(this.filterParams)).data
      this.indicators = [
        {
          title: '所有会展',
          value: data.total,
          unit: '场',
          icon: hyhz,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{...this.filterParams}})
          }
        },
        {
          title: '排期中',
          value: data.pending,
          unit: '场',
          icon: pqgl,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{state:'排期中',...this.filterParams}})
          }
        },
        {
          title: '已结束',
          value: data.finished,
          unit: '场',
          icon: ywc,
          onclick:()=>{
            this.$router.push({path:'/zhanhui/list',query:{state:'已结束',...this.filterParams}})
          }
        },
        {
          title: '总面积',
          value: (data.totalMj / 10000).toFixed(0),
          unit: '万平方米',
          icon: dkmj
        },
        {
          title: '主办单位',
          value: 18191,
          unit: '家',
          icon: fz
        },
        {
          title: '参展商',
          value: data.totalZssl,
          unit: '家',
          icon: sjfw
        },
        {
          title: '参加观众',
          value: 81121,
          unit: '万人',
          icon: jlr
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
