<template>
  <block title="会展面积统计">
    <v-chart class="chart" :option="option"/>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ZhanhuiMjStatBlock",
  props:{
    params:Object
  },
  components: {
    VChart,
    Block
  },
  data(){
    return {
      data:[],
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  computed:{
    option(){
      return {
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '会展面积统计',
            type: 'pie',
            radius: ['35%', '60%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: true,
              overflow:'break'
            },
            data: this.data
          }
        ]
      }
    },
  },
  methods:{
    async init(){
      this.data=(await api.getZhanhuiMjStat(this.params)).data.filter(i=>i.name)
    },
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
