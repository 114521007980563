<template>
  <block title="最新会展">
    <a slot="actions" class="link-button"><img src="../assets/images/dots.png"></a>
    <div class="info-list">
      <div v-for="item in data" :key="item.id" class="info-list-item">
        <div class="info-list-item-dot"></div>
        <div class="info-list-field">
          <a class="link" :href="$router.resolve({path:'/zhanhui/details',query:{id:item.id}}).href">{{item.zhmc}}</a>
        </div>
        <div class="info-list-field info-list-field-fixed" style="width: 60px">{{item.province}}</div>
        <div class="info-list-field info-list-field-fixed" style="width: 100px;">{{item.jbkssj}}</div>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ZhanhuiListBlock",
  components:{Block},
  props:{
    params:Object
  },
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      const params=Object.assign({page:0,size:10,sort:'jbkssj,DESC'},this.params)
      this.data=(await api.listZhanhui(params)).data.content
    }
  }
}
</script>

<style scoped>

</style>
