<template>
  <block title="会展行业">
    <table class="industry-table">
      <tr v-for="group of industries" :key="group.name">
        <td class="industry-table-td1" @click="openIndustry(group)">{{group.name}}({{group.value}})</td>
        <td class="industry-table-td2">
          <span class="industry-table-child" v-for="child of group.children" :key="child.name"
                @click="openSubIndustry(child)">{{child.name}}({{child.value}})</span>
        </td>
      </tr>
    </table>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ZhanhuiSshyStatBlock",
  props:{
    params:Object
  },
  components: {
    Block
  },
  data(){
    return {
      industries:[
        {
          name:'工业品',
          value:0,
          children:[
            { name:'机械工业', value:0 },
            { name:'电子电力', value:0 },
            { name:'光电技术', value:0 },
            { name:'交通工具', value:0 },
            { name:'运输物流', value:0 },
            { name:'汽摩配件', value:0 },
            { name:'仪器仪表', value:0 },
            { name:'暖通制冷', value:0 },
            { name:'信息通信', value:0 },
            { name:'安全防护', value:0 },
          ]
        },
        {
          name:'原材料',
          value:0,
          children:[
            { name:'建材五金', value:0 },
            { name:'能源矿产', value:0 },
            { name:'钢铁冶金', value:0 },
            { name:'纺织纺机', value:0 },
            { name:'印刷包装', value:0 },
            { name:'化工橡塑', value:0 },
            { name:'环保水处理', value:0 },
          ]
        },
        {
          name:'消费品',
          value:0,
          children:[
            { name:'食品饮料', value:0 },
            { name:'服装配饰', value:0 },
            { name:'皮革鞋业', value:0 },
            { name:'美容美发', value:0 },
            { name:'房产家居', value:0 },
            { name:'孕婴童展', value:0 },
            { name:'旅游行业', value:0 },
            { name:'消费电子', value:0 },
            { name:'婚庆婚博', value:0 },
            { name:'音响乐器', value:0 },
            { name:'礼品玩具', value:0 },
            { name:'医疗保健', value:0 },
            { name:'体育休闲', value:0 },
            { name:'办公用品', value:0 },
            { name:'纸业制品', value:0 },
            { name:'酒店用品', value:0 },
            { name:'奢侈品展', value:0 },
            { name:'宠物行业', value:0 },
            { name:'动漫游戏', value:0 },
            { name:'购物年货', value:0 },
          ]
        },
        {
          name:'综合',
          value:0,
          children:[
            { name:'进出口展', value:0 },
            { name:'创业加盟', value:0 },
            { name:'广告媒体', value:0 },
            { name:'文化教育', value:0 },
            { name:'文化艺术', value:0 },
            { name:'农林牧渔', value:0 }
          ]
        },
      ]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      const data=(await api.getZhanhuiSshyStat(this.params)).data.filter(i=>i.name)
      const map=new Map()
      data.forEach(i=>map.set(i.name,i.value))
      for(const group of this.industries){
        let value=0
        for(const child of group.children){
          child.value=map.get(child.name)||0
          value+=child.value
        }
        group.value=value
      }
    },
    openSubIndustry(i){
      this.$router.push({path:'/zhanhui/list',query:{sshy:i.name,...this.params}})
    },
    openIndustry(i){
      this.$router.push({path:'/zhanhui/list',query:{sshy:i.children.map(n=>n.name),...this.params}})
    }
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
