<template>
  <block :title="dwType+'单位 TOP 10'">
    <div class="info-list">
      <div v-for="(item,index) in data" :key="item.id" class="info-list-item">
        <div class="info-list-field info-list-field-fixed" style="width: 25px;">{{index+1}}</div>
        <div class="info-list-field">
          {{item.name}}
        </div>
        <div class="info-list-field info-list-field-fixed link" style="width: 80px;" @click="openList(item.name)">{{item.value}}场</div>
      </div>
    </div>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";

export default {
  name: "ZhanhuiTopDwBlock",
  props:{
    params:Object,
    dwType:String
  },
  components:{Block},
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      this.data=(await api.getZhanhuiDwStat(Object.assign({dwType:this.dwType,limit:10},this.params))).data
    },
    openList(name){
      this.$router.push({path:'/zhanhui/list',query:{...this.dwType==='主办'?{zbdw:name}:{cbdw:name},...this.params}})
    }
  }
}
</script>

<style scoped>

</style>
