<template>
  <block title="月度展会变化情况">
    <el-date-picker slot="actions" size="mini" style="width: 90px"
        v-model="year" :clearable="false"
        type="year" value-format="yyyy">
    </el-date-picker>
    <v-chart class="chart" :option="option"/>
  </block>
</template>

<script>
import api from "../api";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart} from "echarts/charts";
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ZhanhuiAreaYearStatBlock",
  components: {
    VChart,
    Block
  },
  data() {
    const months=[]
    for(let month=1;month<=12;month++){
      months.push(month+'月')
    }
    return {
      year:new Date().getFullYear()+'',
      data: [],
      areas: ['华东','华南','西南','华北','西北','华中','东北'],
      months
    }
  },
  created() {
    this.init()
  },
  watch:{
    year(){
      this.init()
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {},
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.months,
        },
        yAxis: {
          type: 'value',
        },
        series: this.data.map(i=>{
          return {
            name: i.area,
            data: i.data,
            type: 'bar',
            stack: 'area',
            barMaxWidth:40
          }
        })
      }
    },
  },
  methods: {
    async init() {
      const data=(await api.getZhanhuiAreaMonthStat({year:this.year})).data
      const map={}
      data.forEach(i=>{
        map[i.area+i.month]=i.value
      })
      const areaDataList=[]
      for(const area of this.areas){
        const areaData={
          area,
          data:[]
        }
        for(let month=1;month<=12;month++){
          areaData.data.push(map[area+month]||0)
        }
        areaDataList.push(areaData)
      }
      this.data = areaDataList
    },
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
