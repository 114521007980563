<template>
  <block title="地点探查">
    <el-row :gutter="20">
      <el-col :span="10">
        <p class="text">{{changguan?changguan.jj.substring(0,200):zhanhui.jbcg}}...</p>
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="标记附近酒店"></el-checkbox>
          <el-checkbox label="标记附近会展场馆"></el-checkbox>
          <el-checkbox label="标记附近同期展会"></el-checkbox>
        </el-checkbox-group>
      </el-col>
      <el-col :span="14">
        <div id="container"></div>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import AMapLoader from '@amap/amap-jsapi-loader';

window._AMapSecurityConfig = {
  securityJsCode: '166aab4f4b5d52dbc5ead115ae1f7126',
}

export default {
  name: "ZhanhuiDdtcBlock",
  components: {Block},
  props: {
    zhanhui: Object
  },
  data() {
    return {
      changguan: null,
      checkList:[]
    }
  },
  created() {
    this.init()
  },
  mounted() {
    //DOM初始化完成进行地图初始化
    this.initMap();
  },
  beforeDestroy() {
    this.map.destroy();
  },
  methods: {
    async init() {
      this.changguan = (await api.getChangguanByName(this.zhanhui.jbcg)).data
    },
    initMap() {
      AMapLoader.load({
        key: "0ebc613ce16f5d7acfa059d9439a6128",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.ToolBar', 'AMap.Scale', 'AMap.PlaceSearch'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
        this.map = new AMap.Map("container", {  //设置地图容器id
          viewMode: "2D",    //是否为3D地图模式
          zoom: 10,  //初始化地图层级
          center: [116.397428, 39.90923]  //初始化地图中心点,
        });
        this.toolbar = new AMap.ToolBar(); // 缩放工具条实例化
        this.map.addControl(this.toolbar);
        this.scale = new AMap.Scale(); // 比例尺实例化
        this.map.addControl(this.scale);

        this.placeSearch = new AMap.PlaceSearch({
          pageSize: 1, // 单页显示结果条数
          pageIndex: 1, // 页码
          map: this.map, // 展现结果的地图实例
          autoFitView: false
        });

        this.placeSearch.search(this.zhanhui.jbcg,(status, result) => {
          // 查询成功时，result即对应匹配的POI信息
          console.log(status,result)
          this.map.setZoomAndCenter(15, result.poiList.pois[0].location);
        });

      }).catch(e => {
        console.log(e);
      })
    },
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 350px;
}
</style>
