<template>
  <block title="排行榜">
    <div class="rank-row">
      <div class="rank-col" style="width: 50%">
        <zhanhui-ranked-by-jbsj :params="params"></zhanhui-ranked-by-jbsj>
      </div>
      <div class="rank-col-sep"></div>
      <div class="rank-col" style="width: 50%">
        <zhanhui-ranked-by-mj :params="params"></zhanhui-ranked-by-mj>
      </div>
    </div>
    <div class="rank-row">
      <div class="rank-col" style="width: 33%">
        <zhanhui-ranked-by-jbcg :params="params"></zhanhui-ranked-by-jbcg>
      </div>
      <div class="rank-col-sep"></div>
      <div class="rank-col" style="width: 33%">
        <zhanhui-ranked-by-dw dw-type="主办"></zhanhui-ranked-by-dw>
      </div>
      <div class="rank-col-sep"></div>
      <div class="rank-col" style="width: 33%">
        <zhanhui-ranked-by-dw dw-type="承办"></zhanhui-ranked-by-dw>
      </div>
    </div>
  </block>
</template>

<script>
import Block from "./Block";
import ZhanhuiRankedByJbsj from "./ZhanhuiRankedByJbsj";
import ZhanhuiRankedByMj from "./ZhanhuiRankedByMj";
import ZhanhuiRankedByJbcg from "./ZhanhuiRankedByJbcg";
import ZhanhuiRankedByDw from "./ZhanhuiRankedByDw";

export default {
  name: "ZhanhuiRankBlock",
  components:{Block, ZhanhuiRankedByJbsj,ZhanhuiRankedByMj,ZhanhuiRankedByJbcg,ZhanhuiRankedByDw},
  props:{
    params:Object
  }
}
</script>

<style scoped>

</style>
