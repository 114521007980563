<template>
  <div>
    <div class="rank-title">
      <span>会展展期</span>
      <span class="rank-title-top10">TOP10</span>
    </div>
    <div class="info-list">
      <div v-for="(item,index) in data" :key="item.id" class="info-list-item">
        <div class="info-list-field">
          <a class="link" :href="$router.resolve({path:'/zhanhui/details',query:{id:item.id}}).href">{{index+1}}、{{item.zhmc}}</a>
        </div>
        <div class="info-list-field info-list-field-fixed" style="width: 100px;">{{item.jbsj}}天</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api";
import moment from "moment";
const DATE_FMT='YYYY-MM-DD';

export default {
  name: "ZhanhuiRankedByJbsj",
  props:{
    params:Object
  },
  data(){
    return {
      data:[]
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  methods:{
    async init(){
      const params=Object.assign({page:0,size:10,sort:'jbsj,DESC'},this.params)
      const data=(await api.listZhanhui(params)).data.content
      data.forEach(i=>{
        i.jbsj=moment(i.jbjssj,DATE_FMT).diff(moment(i.jbkssj,DATE_FMT),"days")
      })
      this.data=data
    }
  }
}
</script>

<style scoped>

</style>
