<template>
  <div class="page-content" v-if="data">
    <div class="page-tips">数据来源于E展网</div>
    <div class="search-form" style="margin-top: 10px;">
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会展行业：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <tag-select :tags="industries" v-model="searchForm.sshy" multiple></tag-select>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会展地区：</div>
          <div class="search-form-field-content">
            <collapse collapsed-height="36px">
              <div class="area-provinces-select">
                <div class="area-provinces-select-item" v-for="i in areaAndProvinces" :key="i.area" :style="{width:i.width}">
                  <div class="area-provinces-select-area">{{i.area}}：</div>
                  <tag-select class="area-provinces-select-provinces" :tags="i.provinces" v-model="i.selected" multiple></tag-select>
                </div>
              </div>
            </collapse>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会展时间：</div>
          <div class="search-form-field-content">
            <tag-select :tags="['已结束','排期中','展会开始']" v-model="searchForm.state"></tag-select>
            <el-date-picker style="width: 250px;margin-left: 10px;" clearable
                            v-model="searchForm.dateRange"
                            type="daterange"
                            range-separator="至"
                            start-placeholder="开始日期" size="small" unlink-panels
                            end-placeholder="结束日期">
            </el-date-picker>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">主办方：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zbdw" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">承办方：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.cbdw" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="search-form-row">
        <div class="search-form-field">
          <div class="search-form-field-label">会展：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zhmcLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">场馆：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.jbcgLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">面积：</div>
          <div class="search-form-field-content v-center">
            <el-input-number v-model="searchForm.zhmjGe" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>~</span>
            <el-input-number v-model="searchForm.zhmjLe" style="width: 100px;" size="mini" :controls="false"></el-input-number>
            <span>㎡</span>
          </div>
        </div>
        <div class="search-form-field">
          <div class="search-form-field-label">主承办方：</div>
          <div class="search-form-field-content v-center">
            <el-input v-model="searchForm.zcbdwLike" style="width: 200px;" size="mini"></el-input>
          </div>
        </div>
      </div>
      <div class="search-form-bottom">
        <el-button type="primary" @click="search">检索</el-button>
        <el-button @click="reset">重置</el-button>
      </div>
    </div>
    <div style="background-color: #FFFFFF;padding: 20px;margin-top: 20px;">
      <el-table :data="data.content" stripe class="large-font-table">
        <el-table-column prop="zhmc" label="会展" show-overflow-tooltip>
          <a class="link" slot-scope="{row}" :href="$router.resolve({path:'/zhanhui/details',query:{id:row.id}}).href">{{row.zhmc}}</a>
        </el-table-column>
        <el-table-column prop="sshy" label="行业" width="90" show-overflow-tooltip></el-table-column>
        <el-table-column label="时间" width="240">
          <span slot-scope="{row}">{{row.jbkssj}} ~ {{row.jbjssj}}</span>
        </el-table-column>
        <el-table-column prop="zhmj" label="面积（㎡）" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="jbcg" label="场馆" width="200" show-overflow-tooltip></el-table-column>
      </el-table>
      <el-pagination layout="prev, pager, next" background
                     :total="data.totalElements" :current-page="data.number+1"
                     @current-change="loadData">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api";
import TagSelect from "./TagSelect";
import moment from "moment";
import Collapse from "./Collapse";
const DATE_FMT='YYYY-MM-DD';
export default {
  name: "ZhanhuiList",
  components:{TagSelect,Collapse},
  data(){
    return {
      industries:["机械工业","电子电力","光电技术","交通工具","运输物流","汽摩配件","仪器仪表","暖通制冷","信息通信","安全防护","建材五金","能源矿产","钢铁冶金","纺织纺机","印刷包装","化工橡塑","环保水处理","食品饮料","服装配饰","皮革鞋业","美容美发","房产家居","孕婴童展","旅游行业","消费电子","婚庆婚博","音响乐器","礼品玩具","医疗保健","体育休闲","办公用品","纸业制品","酒店用品","奢侈品展","宠物行业","动漫游戏","购物年货","进出口展","创业加盟","广告媒体","文化教育","文化艺术","农林牧渔",'其它'],
      areaAndProvinces:[
        {
          area:"华东",
          provinces:["山东", "江苏", "上海", "浙江", "福建", "安徽", "江西"],
          selected:[],
          width:'40%',
        },
        {
          area:"华南",
          provinces:["广东", "广西", "海南"],
          selected:[],
          width:'30%',
        },
        {
          area:"西南",
          provinces:["四川", "云南", "重庆", "贵州", "西藏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华北",
          provinces:["河北", "北京", "天津", "内蒙古", "山西"],
          selected:[],
          width:'40%',
        },
        {
          area:"西北",
          provinces:["陕西", "新疆", "甘肃", "青海", "宁夏"],
          selected:[],
          width:'30%',
        },
        {
          area:"华中",
          provinces:["湖南", "湖北", "河南"],
          selected:[],
          width:'30%',
        },
        {
          area:"东北",
          provinces:["辽宁", "吉林", "黑龙江"],
          selected:[],
          width:'40%',
        }
      ],
      searchForm:{
        sshy:[],
        state:null,
        dateRange:null,
        zhmcLike:null,
        jbcgLike:null,
        zhmjGe:undefined,
        zhmjLe:undefined,
        zcbdwLike:null,
        zbdw:null,
        cbdw:null
      },
      searchParams:{
        sshy:null,
        province:null,
        state:null,
        startDate:null,
        endDate:null,
        zhmcLike:null,
        jbcgLike:null,
        zhmjGe:undefined,
        zhmjLe:undefined,
        zcbdwLike:null,
        zbdw:null,
        cbdw:null
      },
      data:null
    }
  },
  created() {
    this.init()
  },
  methods:{
    async init(){
      if(this.$route.query.state){
        this.searchForm.state=this.$route.query.state
      }
      if(this.$route.query.startDate&&this.$route.query.endDate){
        this.searchForm.dateRange=[
          moment(this.$route.query.startDate,DATE_FMT),
          moment(this.$route.query.endDate,DATE_FMT)
        ]
      }
      if(this.$route.query.area){
        const i=this.areaAndProvinces.find(i=>i.area===this.$route.query.area)
        if(i){
          i.selected=[...i.provinces]
        }
      }
      if(this.$route.query.sshy){
        if(Array.isArray(this.$route.query.sshy)){
          this.searchForm.sshy=this.$route.query.sshy
        }else{
          this.searchForm.sshy=[this.$route.query.sshy]
        }
      }
      this.searchForm.zbdw=this.$route.query.zbdw
      this.searchForm.cbdw=this.$route.query.cbdw
      this.searchForm.jbcgLike=this.$route.query.jbcgLike
      await this.search()
    },
    async search(){
      this.searchParams.sshy=this.searchForm.sshy
      const province=this.areaAndProvinces.reduce((acc,v)=>{
        acc.push(...v.selected)
        return acc
      },[])
      if(province.length===0){
        this.searchParams.province=null
      }else{
        this.searchParams.province=province
      }
      if(this.searchForm.state==='已结束'){
        this.searchParams.state='END'
      }else if(this.searchForm.state==='排期中'){
        this.searchParams.state='PENDING'
      }else if(this.searchForm.state==='展会开始'){
        this.searchParams.state='OPENING'
      }else{
        this.searchParams.state=null
      }
      if (this.searchForm.dateRange) {
        this.searchParams.startDate = moment(this.searchForm.dateRange[0]).format(DATE_FMT)
        this.searchParams.endDate = moment(this.searchForm.dateRange[1]).format(DATE_FMT)
      }else{
        this.searchParams.startDate = null
        this.searchParams.endDate = null
      }
      this.searchParams.zhmcLike=this.searchForm.zhmcLike
      this.searchParams.jbcgLike=this.searchForm.jbcgLike
      this.searchParams.zhmjGe=this.searchForm.zhmjGe
      this.searchParams.zhmjLe=this.searchForm.zhmjLe
      this.searchParams.zcbdwLike=this.searchForm.zcbdwLike
      this.searchParams.zbdw=this.searchForm.zbdw
      this.searchParams.cbdw=this.searchForm.cbdw

      await this.loadData(1)
    },
    async reset(){
      this.searchForm.sshy=[]
      this.areaAndProvinces.forEach(i=>i.selected=[])
      this.searchForm.state=null
      this.searchForm.dateRange=null
      this.searchForm.zhmcLike=null
      this.searchForm.jbcgLike=null
      this.searchForm.zhmjGe=undefined
      this.searchForm.zhmjLe=undefined
      this.searchForm.zcbdwLike=null
    },
    async loadData(page){
      const params={
        ...this.searchParams,
        type:'展会',
        page:page-1,
        size:10,
        sort:'jbkssj,DESC'
      }
      this.data=(await api.listZhanhui(params)).data
    }
  }
}
</script>

<style scoped>

</style>
