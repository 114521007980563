<template>
  <block title="历届对比">
    <el-row>
      <el-col :span="12">
        <el-timeline reverse>
          <el-timeline-item
              v-for="(activity, index) in data"
              :key="index"
              :timestamp="activity.jbkssj" hide-timestamp :color="activity.id===zhanhui.id?'#0356AD':null">
            <div class="timeline-content" :style="{color:activity.id===zhanhui.id?'#0356AD':null}">
              <span style="flex: none;margin-right: 10px;">{{activity.jbkssj}}</span>
              <span style="flex: none;margin-right: 10px;">{{ activity.province }}</span>
              <a class="link" :href="$router.resolve({path:'/zhanhui/details',query:{id:activity.id}}).href" style="overflow:hidden;white-space: nowrap;text-overflow: ellipsis;">{{ activity.zhmc }}</a>
            </div>
          </el-timeline-item>
        </el-timeline>
      </el-col>
      <el-col :span="12">
        <v-chart class="chart" :option="chartOption"/>
      </el-col>
    </el-row>
  </block>
</template>

<script>
import api from "../api";
import Block from "./Block";
import {use} from "echarts/core";
import {CanvasRenderer} from "echarts/renderers";
import {BarChart, LineChart} from "echarts/charts";
import {GridComponent, LegendComponent, TitleComponent, TooltipComponent} from "echarts/components";
import VChart from "vue-echarts";
import moment from "moment";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
]);
export default {
  name: "ZhanhuiLjdbBlock",
  components: {Block, VChart},
  props: {
    zhanhui: Object
  },
  data() {
    return {
      data: []
    }
  },
  created() {
    this.init()
  },
  computed: {
    chartOption() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        legend: {
          data: ['展会面积', '展期', '展商数量']
        },
        xAxis: {
          type: 'category',
          data: this.data.map(i => i.jbkssj),
          axisLabel: {
            rotate: 45
          }
        },
        yAxis: [
          {
            type: 'value',
          },
          {
            type: 'value',
          }
        ],
        series: [
          {
            name: '展会面积',
            data: this.data.map(i => i.zhmj?i.zhmj/10000:null),
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value?(value + '万平方米'):'-';
              }
            },
          },
          {
            name: '展期',
            data: this.data.map(i => moment(i.jbjssj).diff(moment(i.jbkssj),"days")+1),
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value?(value + '天'):'-';
              }
            },
          },
          {
            name: '展商数量',
            data: this.data.map(i => i.zssl),
            type: 'line',
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value?(value + '家'):'-';
              }
            },
          },
        ]
      }
    }
  },
  methods: {
    async init() {
      this.data = (await api.listZhanhui({
        groupId: this.zhanhui.groupId,
        page: 0,
        size: 10,
        sort: 'jbkssj,DESC'
      })).data.content.reverse()
    }
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
