<template>
  <block title="地域情况">
    <el-row>
      <el-col :span="12">
        <v-chart class="chart" :option="barOption" @click="onClick"/>
      </el-col>
      <el-col :span="12">
        <v-chart class="chart" :option="mapOption" @click="onClick"/>
      </el-col>
    </el-row>
    <el-button v-if="province" style="position: absolute;top:10px;right:10px" type="primary" @click="back">返回</el-button>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart,MapChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  MapChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent
]);

import '../echarts/map'
import Block from "./Block";

export default {
  name: "ZhanhuiAreaStatBlock",
  components: {
    VChart,
    Block
  },
  props:{
    params:Object
  },
  data(){
    return {
      provinces:[],
      province:null,
      cities:[],
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  computed:{
    barOption(){
      if(!this.province){
        const topTen = this.provinces.slice(0,10)
        return {
          tooltip: {
            trigger: "item",
          },
          xAxis: {
            type: 'category',
            data: topTen.map(i=>i.name),
            axisLabel:{
              rotate:45
            }
          },
          yAxis: {
            type: 'value',
            name: '数量（个）'
          },
          series: [
            {
              data: topTen.map(i=>i.value),
              type: 'bar',
              label:{
                show:true,
                position:'top'
              }
            }
          ]
        }
      }else{
        const topTen = this.cities.slice(0,10)
        return {
          xAxis: {
            type: 'category',
            data: topTen.map(i=>i.name),
            axisLabel:{
              rotate:45
            }
          },
          yAxis: {
            type: 'value',
            name: '数量（个）'
          },
          series: [
            {
              data: topTen.map(i=>i.value),
              type: 'bar',
              label:{
                show:true,
                position:'top'
              }
            }
          ]
        }
      }
    },
    mapOption(){
      if(!this.province){
        return {
          title: [
            {
              textStyle: {
                color: "#000",
                fontSize: 18
              },
              subtext: "",
              text: '中国',
              top: "auto",
              subtextStyle: {
                color: "#aaa",
                fontSize: 12
              },
              left: "auto"
            }
          ],
          visualMap: {
            min: 0,
            max: this.provinces.reduce((s,v)=>{return v.value>s?v.value:s},0),
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['#FDEDED', '#F00B0D']
            }
          },
          series: [
            {
              map: 'china',
              data: this.provinces,
              type: "map",
              label:{
                show:true
              }
            }
          ]
        }
      }else{
        return {
          title: [
            {
              textStyle: {
                color: "#000",
                fontSize: 18
              },
              subtext: "",
              text: this.province,
              top: "auto",
              subtextStyle: {
                color: "#aaa",
                fontSize: 12
              },
              left: "auto"
            }
          ],
          visualMap: {
            min: 0,
            max: this.cities.reduce((s,v)=>{return v.value>s?v.value:s},0),
            text: ['High', 'Low'],
            realtime: false,
            calculable: true,
            inRange: {
              color: ['#FDEDED', '#F00B0D']
            }
          },
          series: [
            {
              map: this.province,
              data: this.cities,
              name: "",
              type: "map",
              roam: true,
              label:{
                show:true
              }
            }
          ]
        }
      }
    }
  },
  methods:{
    async init(){
      this.provinces=(await api.getZhanhuiProvinceStat(this.params)).data.filter(i=>i.name)
    },
    onClick(evt){
      if(this.province){
        return
      }
      this.drillToProvince(evt.name)
    },
    async drillToProvince(province){
      this.province=province
      this.cities=(await api.getZhanhuiCityStat(Object.assign({province},this.params))).data
    },
    async back(){
      this.province=null
    }
  }
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>
