<template>
  <block :title="title">
    <v-chart class="chart" :option="option"/>
  </block>
</template>

<script>
import api from "../api";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart,LineChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

use([
  CanvasRenderer,
  BarChart,
  LineChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent
]);

import '../echarts/map'
import Block from "./Block";
import moment from "moment";

export default {
  name: "ZhanhuiYearStatBlock",
  components: {
    VChart,
    Block
  },
  props:{
    params:Object,
    title:{
      type:String,
      default:'历年会展情况统计'
    }
  },
  data(){
    return {
      data:[],
    }
  },
  created() {
    this.init()
  },
  watch:{
    params:{
      deep:true,
      handler(){
        this.init()
      }
    }
  },
  computed:{
    option(){
      return {
        grid:{
          left:'60px',
          top:20,
          bottom:20
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        xAxis: {
          type: 'category',
          data: this.data.map(i=>i.name),
          offset: 0
        },
        yAxis: [
          {
            type: 'value',
            splitLine:{
              show:false
            }
          },
          {
            type: 'value',
            splitLine:{
              show:false
            }
          },
        ],
        legend: {
          data: ['会展数', '新增会展比例']
        },
        series: [
          {
            name:'会展数',
            data: this.data.map(i=>i.v1),
            type: 'bar',
            label:{
              show:true,
              position: 'top'
            },
            barMaxWidth:60
          },
          {
            name:'新增会展比例',
            data: this.data.map(i=>i.v2),
            type: 'line',
            yAxisIndex: 1,
            label:{
              show:true,
              position: 'top',
              formatter: '{c}%'
            },
            tooltip: {
              valueFormatter: function (value) {
                return value?(value + '%'):'-';
              }
            },
          }
        ]
      }
    },
  },
  methods:{
    async init(){
      const data1=(await api.getZhanhuiJbkssjYearStat(this.params)).data
      const data2=(await api.getZhanhuiJbkssjYearStat(Object.assign({firstSession:true},this.params))).data
      const data=[]
      const startYear=moment(this.params.startDate,'YYYY-MM-DD').year()
      const endYear=moment(this.params.endDate,'YYYY-MM-DD').year()
      for(let year=startYear;year<=endYear;year++){
        const item1=data1.find(i=>i.name==year)
        const item2=data2.find(i=>i.name==year)
        data.push({
          name:year+'',
          v1:item1?item1.value:0,
          v2:item1&&item2?(item2.value*100/item1.value).toFixed(2):0
        })
      }
      this.data=data
    },
  }
}
</script>

<style scoped>
.chart {
  height: 300px;
}
</style>
