<template>
  <div class="block-body" style="position: relative;">
    <div class="company-list-scroll">
      <div class="left-scroll-button"></div>
      <div class="icca-list">
        <ul>
          <li v-for="item in data" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
            </div>
          </li>
        </ul>
        <ul>
          <li v-for="item in data1" :key="item.id">
            <div class="li_div">
              <div class="content">{{ item.name}}</div>
            </div>
          </li>
        </ul>
      </div>
      <div class="right-scroll-button"></div>
    </div>
  </div>
</template>

<script>
import AttachmentAttrDirective from "./AttachmentAttrDirective";

export default {
  name: "UfiCertified",
  components: {},
  directives: {
    'attachment-attr': AttachmentAttrDirective
  },
  data() {
    return {
      data: [
        {
          id: 165726,
          name: '中国温州国际眼镜展',
        },
        {
          id: 165572,
          name: '国际电线电缆工业贸易展览会',
        },
        {
          id: 723177,
          name: '世界健康博览会',
        },
        {
          id: 165529,
          name: '世界半导体大会暨南京国际半导体博览会',
        },
        {
          id: 165530,
          name: '西安国际汽车工业展览会',
        },
        {
          id: 165068,
          name: '长三角国际文化产业博览会',
        },
        {
          id: 165653,
          name: '沉阳国际广告博览会',
        },
        {
          id: 165654,
          name: '深圳国际家具展',
        },
        {
          id: 165655,
          name: '沉阳国际广告博览会',
        },
        {
          id: 165656,
          name: '深圳国际工业设计博览会',
        },
      ],
      data1: [
        {
          id: 165726,
          name: '深圳国际机械制造工业展览会',
        },
        {
          id: 165572,
          name: '深圳国际旅游博览会',
        },
        {
          id: 723177,
          name: '中国杭州国际汽车工业展览会',
        },
        {
          id: 165529,
          name: '国际电力设备及技术展览会',
        },
        {
          id: 165068,
          name: '四川国际大健康产业博览会',
        },
        {
          id: 165653,
          name: '国际管材工业贸易展览会',
        },
        {
          id: 165654,
          name: '山东国际电动汽车及新能源汽车展览会',
        },
        {
          id: 165655,
          name: '山东国际节能与新能源汽车展览会',
        },
        {
          id: 165656,
          name: '山东国际给水、排水、水处理、管道及泵阀展览会',
        },
        {
          id: 165657,
          name: '山东国际供暖、通风、空调及家居舒适系统展览会',
        },
      ]
    }
  },
  created() {
    this.init()
  },
  methods: {
    async init() {
    }
  }
}
</script>

<style scoped>
.icca-list{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 20px;
}
ul {
  list-style: none; /* 移除默认的列表标记 */
  padding: 0px; /* 移除默认的内边距 */
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}
li {
  position: relative; /* 为伪元素定位做准备 */
  padding-left: 20px; /* 留出空间显示自定义标记 */
  padding-bottom: 14px;
}
li::before {
  content: "•"; /* 自定义标记，这里是一个实心圆点 */
  font-size: 20px;
  position: absolute; /* 绝对定位，相对于其父元素（li） */
  left: 0; /* 放置在列表项文本的左侧 */
  color: #BFD9F4; /* 设置标记颜色 */
}
.li_div{
  width: 430px;
  display: flex;
  justify-content: space-between;
  /*align-items: center;*/
  flex-wrap: wrap;
}
.content {
  /* 可以在这里添加具体内容的样式 */
}
.content1 {
  /* 可以在这里添加具体内容的样式 */
  color: #555555;
}
</style>
