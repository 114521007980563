<template>
  <block title="会展国际组织">
    <div class="zhanhui-orgs">
      <a class="zhanhui-org" href="https://www.ufi.org/" target="_blank">
        <el-image :src="require('../assets/images/uif.png')" lazy fit="fill"></el-image>
        <div>UF国际展览业协会</div>
      </a>
      <a class="zhanhui-org" href="https://www.iccaworld.org/" target="_blank">
        <el-image :src="require('../assets/images/icca.png')" lazy fit="fill"></el-image>
        <div>ICCA国际大会及会议协会</div>
      </a>
      <a class="zhanhui-org" href="https://www.iaee.com/" target="_blank">
        <el-image :src="require('../assets/images/iaee.png')" lazy fit="fill"></el-image>
        <div>IAEE国际展范与项目协会</div>
      </a>
      <a class="zhanhui-org" href="https://www.siso.org/" target="_blank">
        <el-image :src="require('../assets/images/siso.png')" lazy fit="fill"></el-image>
        <div>SISO独立组展商协会</div>
      </a>
      <a class="zhanhui-org" href="https://www.auma.de/en" target="_blank">
        <el-image :src="require('../assets/images/auma.png')" lazy fit="fill"></el-image>
        <div>AUMA德国展竟业协会</div>
      </a>
      <a class="zhanhui-org" href="https://www.mpi.org/" target="_blank">
        <el-image :src="require('../assets/images/mpi.png')" lazy fit="fill"></el-image>
        <div>MPI国际会议组织者联盟</div>
      </a>
      <a class="zhanhui-org" href="https://siteglobal.com/" target="_blank">
        <el-image :src="require('../assets/images/site.png')" lazy fit="fill"></el-image>
        <div>SITE国际奖励旅游管理者协会</div>
      </a>
    </div>
  </block>
</template>

<script>
import Block from "./Block";

export default {
  name: "ZhanhuiOrgBlock",
  components:{Block},
}
</script>

<style scoped>

</style>
